@import "../../ui.scss";

.Group {
  display: flex;
  align-items: center;

  $groupSpace: $space;

  &.smallSpacing {
    $groupSpace: $space-small;
  }

  > * {
    overflow: hidden;
    flex-shrink: 1;
  }

  > *:not(:last-child) {
    margin-right: $groupSpace;
  }

  &.alignRight {
    justify-content: flex-end;

    > *:not(:last-child) {
      margin-right: 0;
    }

    > *:not(:first-child) {
      margin-left: $groupSpace;
    }
  }

  &.alignCenter {
    justify-content: center;

    > * {
      margin: 0 $space-small;
    }
  }

  &.alignSpaceBetween {
    justify-content: space-between;
    flex-grow: 1;

    > * {
      margin: 0;
    }
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;

    &.alignRight {
      align-items: flex-end;
    }

    &.alignCenter {
      align-items: center;
    }

    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: $groupSpace;
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.verticalAlignTop {
    align-items: flex-start;
  }
  &.verticalAlignCenter {
    align-items: center;
  }
  &.verticalAlignBottom {
    align-items: flex-end;
  }
}
