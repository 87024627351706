@import "../../ui.scss";

.Divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $colorLightGrey;
  margin: $space 0;

  &.vertical {
    height: 100%;
    width: 1px;
    margin: 0 $space-small;
  }

  &.hidden {
    background-color: transparent;
  }

  &.noMargin {
    margin: 0;
  }
}

.DividerWithText {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin: $space 0;

  .line {
    height: 1px;
    background-color: $colorLightGrey;
    flex-grow: 1;
  }

  .text {
    display: inline-block;
    padding: $space-small $space;
  }

  .textStrong {
    font-weight: $font-weight-bold;
  }

  &.textLeft {
    .line:first-child {
      flex-grow: 0;
      width: $space;
    }
  }

  &.textRight {
    .line:last-child {
      flex-grow: 0;
      width: $space;
    }
  }
}
