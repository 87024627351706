@import "../../ui.scss";

.ModalContainer {
  margin: $space-large 3rem $space-large $space-large;
  max-height: 90%;
  width: auto;
  max-width: 90%;
  min-width: 40%;
  overflow: visible;
  z-index: 1005; // for use with antd dropdowns in modals etc
  position: relative;
  display: flex;
  flex-direction: column;

  &.full {
    width: 90%;
    height: 90%;
  }
}

.ModalCloseButton {
  position: absolute;
  right: -69px;
  top: 0;
}

.Modal {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fff;
  align-items: flex-start;
}

  .ModalBody {
    padding: $space;
    overflow: auto;
    width: 100%;
  }
  .ModalHeader {
    padding: $space;
    width: 100%;
  }
  .ModalFooter {
    padding: $space;
    align-items: flex-end;
    width: 100%;
  }
