@import "../../ui.scss";

//  colors

.color-default {
  color: inherit;
}

.color-red {
  color: $colorRed;
}

.color-green {
  color: $colorGreen;
}

.color-grey {
  color: $colorGrey;
}

.color-lightgrey {
  color: $colorLightGrey;
}

.color-yellow {
  color: $colorYellow;
}

//  sizes

.size-small-x {
  font-size: 0.8rem;
}

.size-small {
  font-size: 1rem;
}

.size-default {
  font-size: 1.3rem;
}

.size-large {
  font-size: 1.5rem;
}

.size-large-x {
  font-size: 1.7rem;
}

.size-large-xx {
  font-size: 2rem;
}
