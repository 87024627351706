@import '~antd/dist/antd.css';
@import '~highlight.js/styles/github.css';
@import '~highlight.js/styles/dracula.css';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  background: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
}

#root {
  height: 100%;
}

@import 'lib/ui/ui.scss';
@import 'assets/styles/antd';
