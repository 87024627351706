@import "variables";

@mixin hoverBefore {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: $border-radius;
  transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@mixin hoverLightEffect {
  position: relative;

  &:hover {
    &::after {
      opacity: 0.8;
    }
  }

  &::after {
    @include hoverBefore;
    background: #fff;
  }
}

@mixin hoverDarkEffect {
  position: relative;

  &:hover {
    &::after {
      opacity: 0.1;
    }
  }

  &::after {
    @include hoverBefore;
    background: #000;
  }
}

@mixin hoverBorderCenter {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -3px;
    width: 60%;
    height: 1px;
    opacity: 0;
    background-color: #5778F3;
    transform: translate(-50%, 0);
    transition: all 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
    opacity: 1;
  }
}

