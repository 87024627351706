@import '../../ui.scss';

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $space-small $space;
  outline: none;
  cursor: pointer;
  background-color: $colorDirtyWhite;
  border: 1px solid $colorLightGrey;
  border-radius: $border-radius;
  white-space: nowrap;
  position: relative;
  max-height: 100%;
  line-height: 1rem;
  min-width: 2rem;

  &:hover,
  &:focus {
    background-color: #e6e6e6;
    border-color: #c6c6c6;
  }

  &:before {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background: #fff;
    opacity: 0.35;
    content: '';
    border-radius: inherit;
    z-index: 1;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    pointer-events: none;
    display: none;
  }

  //  children

  a {
    color: inherit;
    text-decoration: inherit;
  }
}

//  variants

.vPrimary {
  background: #1890ff;
  color: #fff;
  border-color: #1890ff;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
}

.vPlain {
  background: transparent;
  border-color: transparent;

  &:hover,
  &:focus {
    background: transparent;
    border-color: transparent;
  }

  @include hoverDarkEffect;
}

//  states

.disabled {
  &:before {
    display: block;
  }
  cursor: no-drop;
}

.loading {
  &:before {
    display: block;
  }
  cursor: wait;
}

//  others

.icon {
  display: flex;
  align-items: center;
}

.iconWithMargin {
  margin-right: $space-small;
}

.label {
}

// sizes

.small {
  padding: $space-small-x $space-small;
  font-size: 0.7rem;
  line-height: 1.3rem;
  border-radius: 2px;

  .iconWithMargin {
    margin-right: $space-small-x;
  }
}

.large {
  padding: $space $space-large;
  font-size: 1.1rem;
  font-weight: $font-weight-bold;
  line-height: 1.5rem;

  .iconWithMargin {
    margin-right: $space;
  }
}
