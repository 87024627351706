@import "../../ui.scss";

.DateTime {
  position: relative;
}

.DateTimeWithIcons {
  display: flex;
  align-items: center;
  justify-content: inherit;

  > span {
    margin-right: $space-small;
  }
}
