@import "../../ui.scss";

.EmptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: $space;
  background: $colorWhite;
  border-radius: $border-radius;
  border: 1px solid $colorLightGrey;
}

.EmptyStateSimple {
  background: transparent;
  border-color: transparent;
}

.EmptyStateInline {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .Message,
  .Children {
    margin-top: 0;
    margin-left: $space-large;
  }
}

.Message,
.Children {
  margin-top: $space-large;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Icon {
  opacity: 0.5;
}
