@import "../../ui.scss";

.GridBox {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: $space;

  @include above(lg) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $space-large;
  }

  @include above(lgx) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $space-large-x;
  }
}
