@import "../../ui.scss";

.Root {
  background: #fff;
  border-radius: $border-radius;
  margin: $space;
  padding: $space;
  border-top: 1px solid #dedede;
  position: relative;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  &.noShadow {
    box-shadow: none;
    border: 1px solid #dedede;
  }

  &.noMargin {
    margin: 0;
  }

  &.noPadding {
    padding: 0;
  }
}

.hoverable {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

.withPaddingBottom {
  padding-bottom: 3rem;
}

.stretched {
  height: 100%;
}

.raised {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.BoxFooter {
  position: absolute;
  right: $space-small;
  bottom: $space-small;
  text-align: right;
  color: $colorPrimaryLight;
  padding: $space-small-x $space-small;
  display: flex;
  align-items: center;
}

.paper {
  &:before,
  &:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 10px;
    width: 50%;
    top: 80%;
    background: $colorGrey;
    box-shadow: 0 15px 10px $colorGrey;
    transform: rotate(-3deg);
  }
  &:after {
    transform: rotate(3deg);
    right: 10px;
    left: auto;
  }
}

.dirty {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    top: 50%;
    bottom: 0;
    left: 10px;
    right: 10px;
    border-radius: 100px / 10px;
  }

  &:after {
    right: 10px;
    left: auto;
    transform: skew(8deg) rotate(3deg);
  }
}


.header {
  margin-bottom: $space-large;
}

.headerWithBackground {
  background: #A8C5DB;
  /* box-shadow: 0 1px 1px #dedede; */
  margin: -$space;
  margin-bottom: $space-small;
  padding: $space-small $space;
  border-bottom: 1px solid #dedede;
}
