//  space

$space-small-x: 0.25rem;
$space-small: 0.5rem;
$space: 1rem;
$space-large: 1.5rem;
$space-large-x: 2rem;

//  colors

$colorPrimaryLightest: #49DFFF;
$colorPrimaryLighter: #00ABEE;
$colorPrimaryLight: #08C;
$colorPrimary: #4368c5;
$colorPrimaryDark: #3958a5;
$colorPrimaryCompany: #ba1b2a;
$colorSecondary: lighten($colorPrimary, 20%);

$colorGrey: #666;
$colorLightGrey: #d6d6d6;
$colorDirtyWhite: #f6f6f6;
$colorWhite: #fff;
$colorBrown: #473300;
$colorRed: #cf1e2f;
$colorYellow: #ffc000;
$colorGreen: #72ac03;

$backgroundHover: lighten($colorPrimary, 63%);

// typography

$text-color: #222;

$font-family-default: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$font-family-default-old: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-monospace: Consolas, Menlo, Courier New, Courier, monospace;

$font-weight-bold: 600;
$font-weight-medium: 500;
$font-weight-default: 400;
$font-weight-thin: 300;

$font-size-small-x: 0.8rem;
$font-size-small: 0.9rem;
$font-size-default: 1rem;
$font-size-large: 1.15rem;
$font-size-large-x: 1.3rem;
$font-size-large-xx: 1.6rem;
$font-size-large-xxx: 2rem;

$line-height: 1.643rem;
$line-height-headings: 1.2;

$letter-spacing: 0.036rem;

//  shadows

$box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$box-shadow-large: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
  0 2px 4px 0 rgba(0, 0, 0, 0.08);
$box-shadow-large-x: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
  0 5px 15px 0 rgba(0, 0, 0, 0.08);
$box-shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
$box-shadow-outer: 0 0 0 3px rgba(52, 144, 220, 0.5);
$box-shadow-none: none;

//  other

$border-radius: 5px;
